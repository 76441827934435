.dndflow {
    flex-direction: column;
    display: flex;
    flex-grow: 1;
    height: 100%;
}

.dndflow aside {
    border-right: 1px solid #eee;
    padding: 15px 10px;
    font-size: 12px;
    background: #fcfcfc;
}

.dndflow aside .description {
    margin-bottom: 10px;
}

.dndflow .dndnode {
    height: 20px;
    padding: 4px;
    border: 1px solid #1a192b;
    border-radius: 2px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: grab;
}

.dndflow .dndnode.input {
    border-color: #0041d0;
}

.dndflow .dndnode.output {
    border-color: #ff0072;
}

.dndflow .reactflow-wrapper {
    flex-grow: 1;
    height: 100%;
}

.dndflow .selectall {
    margin-top: 10px;
}

@media screen and (min-width: 768px) {
    .dndflow {
        flex-direction: row;
    }

    .dndflow aside {
        width: 20%;
        max-width: 250px;
    }
}

.context-menu {
    background: white;
    /* border-style: solid; */
    box-shadow: 10px 19px 20px rgba(0, 0, 0, 10%);
    position: absolute;
    z-index: 10;
    border-radius: 4px;
}
  
.context-menu button {
    /* border: none;
    display: block;
    padding: 0.5em;
    text-align: left; */
    width: 100%;
}
  
.context-menu button:hover {
    background: white;
}

.context-menu .spin-container {
    list-style: none
}
.context-menu .spin-container li {
    padding: 5px 12px !important;
    cursor: pointer;
}

.context-menu .spin-container li:hover {
    background-color: rgba(100, 100, 100, 0.05);
}