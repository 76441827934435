.node-variable {
    width: 120px;
    height: 40px;
    background: var(--variable-button);
    box-shadow: var(--box-shadow);
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    gap: 6px;
    /* flex-direction: column; */
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    color: #FFFFFF;
}